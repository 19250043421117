._login {
background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100% !important;
  height: 100dvh;

  
  

  ._log_in {
    background-color:#edededaa ;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
     border-radius: 3px;
    margin-bottom: 100px;
    max-width: 350px;
    position: relative;
    width: 100%;
    ._in_h {
      width: 100%;
      height: auto;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 20px;
      h2 {
        color: rgb(255, 255, 255);
      }
      span {
        color: grey;
      }
    }

    .__form {
      width: 100%;
      // max-width: 300px !important;
      // display: flex;
      // flex-direction: column;

      input {
        border: 1px solid #aaaa;
         border-radius: 3px;
        width: 100% !important;
        padding: 10px 15px !important ;
        margin-bottom: 10px;
        outline: none;
      }
      button {
        //border: 1px solid #aaaa;
        outline: none !important;
         border-radius: 3px;
        width: 100%;
        padding: 10px 20px;
        margin-bottom: 20px;
        cursor: pointer;
        border: none;
        transition: 0.3s;
        color: white;
        background-color: #3b82f6;
      }
      button:hover {
        background-color: #d9d9d9;
        transition: 0.3s ease-in;
        color: rgb(49, 49, 49);
        // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.233), 0 4px 12px rgba(0, 0, 0, 0.231);
      }
      ._rg_pg {
        align-items: center;
        justify-content: center;
        height: auto;
        display: flex;

        span {
          font-size: 12px;
          color: grey;
          text-decoration: none !important;
          font-style: none !important;
        }
      }
    }
    .close{
    width: 100%;
    position: absolute;
    left: 10px;
    float: right;
    top: 10px;
    display: flex;
    cursor: pointer;
    p{
      color: white;
    }

  }
  }
}
