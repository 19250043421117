@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,800;0,900;1,600&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: "CustomFont";
  src: url("../src/fonts/SaolDisplay-Regular.ttf");
  src: url("../src/fonts/saoldisplay-regular-webfont.woff") format("woff");
  src: url("../src/fonts/saoldisplay-regular-webfont.woff2") format("woff2");
}
body {
  height: 100vh;
  margin: 0;
  font-family: Rubik, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3 {
  font-family: "CustomFont" !important;
}

button {
  display: inline-block;
  background-color: #0070f3;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 4px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select {
  -webkit-box-shadow: 0 0 0 30px #ecf0f3 inset !important;
}

label {
  font-size: 15px;
  color: #262626;
}
input {
  border: none !important;
   font-size: 14px !important;
}
select{
    border-radius: 3px;
    width: 100% !important;
    padding: 10px 15px !important;
    margin-bottom: 10px;
    outline: none;
}
span {
  font-family: "CustomFont" !important;
  font-size: 2rem !important;
  color: black !important;
}
a {
  text-decoration: none;
}

.__max_w {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(90deg, transparent 50%, #c01e2e 50%);
background-size: 80px 80px;
background-color: #ee1c25;
}
.__min_w {
  max-width: 1200px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 20px;
}

.__button {
  /* border: 1px solid #aaaa; */
  outline: none !important;
  border-radius: 3px;
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer !important;
  border: none;
  transition: all 0.2s ease-in;
  color: white !important;
  background-color: #3b82f6;
}

.__button:hover {
  background-color: #5c9bff;
  transition: 0.2s ease-in;
  color: rgb(255, 255, 255) !important;
}
.__label {
  color: white !important;
  font-size: 12px;

}

/* Apply to the form container */
._form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
}

/* Style for each input and select wrapper */
._form div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

._form input,
._form select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  box-sizing: border-box;
}

/* Specific styling for the grouped elements */
.div_wrp {
  display: flex;
  gap: 10px;
}

.div_wrp > div {
  flex: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .div_wrp {
    flex-direction: column;
  }

  ._form input,
  ._form select {
    width: 100%;
  }
}
