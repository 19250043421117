.info_home {
  margin: 0;
  width: 100vw;
  padding: 50px;
  background-image: repeating-linear-gradient(
    130deg,
    transparent,
    transparent 8px,
    #c7c7c71d 1px,
    #cccccc34 9px
    
  );
  background-color: rgba(0, 0, 0, 0.224);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  place-items: center;
  overflow: hidden;
  .info {
    border-radius: 15px;
    width: 100%;
    min-height: 70dvh;
    background-color: rgba(0, 0, 0, 0.224);
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 1rem;
    .info_img {
      width: 100%;
      height: auto;
      align-items: center;
      justify-content: center;
      display: flex;
      img {
        display: flex;
        width: 400px;
      }
      @media screen and (max-width:900px) {
        img{
            display: flex;
          width: 300px;
        }
        
      }
    }
    .info_text {
      width: 100%;
      height: auto;
      padding: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      display: flex;
      img{
        width: 100%;
      }
      h1 {
        color: white;
        text-align: justify;
      }
      p {
        color: white;
        text-align: justify;
      }
      .__button{
        margin-top: 1rem;

      }
    }
  }
  @media screen and (max-width: 900px) {
    .info {
      grid-template-columns: 1fr;
    }
  }
}
@media screen and (max-width: 900px) {
    .info_home {
     padding: 1rem;
    }
  }
