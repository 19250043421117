.__sucsess {
  margin: 0;
  width: 100%;
  height: auto;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(248, 221, 178, 1) 100%
  );
  //background-image: repeating-linear-gradient( 130deg, transparent, transparent 8px, #c7c7c71d 1px, #cccccc34 9px );
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  place-items: center;
  overflow: hidden;
  flex-direction: column;
  .__suc_con {
    width: 100%;
    height: auto;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    span {
      font-size: 80px !important;
    }
    h1 {
      font-family: custom !important;
      color: orange;
    }
  }
}
.__scu_patry {
  width: 100%;
  img {
    width: 150px;
  }
}
.__social {
  padding: 50px;
}
.__social_icon {
  justify-content: space-around;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;

  div {
    padding: 50px;
    img {
      width: 40px;
    }
  }
}
.social_container {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr;
  div {
    img {
      width: 50px;
    }
  }
}

.explo {


  width: 100%;
  margin-top:50px ;
  padding: 100px 50px  !important;
  .ex_brand_head {
    width: 100%;
    height: auto;

  }
  .brand_logo {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .itms {
      margin-top:20px ;
        background: rgba(248, 221, 178, 0.389) 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px !important;
      img {
        margin: auto;
        width: 10dvw !important;
      }
      img:hover {
        transform: scale(115%);
        transition: .2s ease-in-out ;
       
      }
    }
  }
  @media (max-width: 780px) {
    
    .brand_logo {
      grid-template-columns:  1fr !important;
    }
    .img {
        margin: auto;
        width: 20dvw !important;
      }
    
  }
}
