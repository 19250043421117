@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
.wheel_wrp {
  width: 100%;
  // max-width: 600px;
  min-height: 100dvh;
  height: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  backdrop-filter: blur(1px);
  .wheel_wrp_head {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;

    img {
      width: 200px;
    }
  }

  .win_head {
    width: 100%;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .your_code {
      h2 {
        small {
          color: white;
          font-size: 30px !important;
        }
        font-family: "Bebas Neue", sans-serif !important;
        text-transform: uppercase;
        border: 4px solid rgb(255, 0, 0);
        border-radius: 40px;
        padding: 8px 20px;
        font-size: 30px !important;
        color: rgb(255, 191, 0);
        background-color: black;
      }
    }
  }
  .win_head h1 {
    color: white;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 1);
    font-size: 50px;
    font-family: "Bebas Neue", sans-serif !important;
  }
  .win_head h2 {
    color: white;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 1);
    font-size: 40px;
  }
  ._code {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: rgb(255, 255, 255);
  }
}
.popup {
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100dvh;
  padding: 20px;
  backdrop-filter: blur(2px);
  background-color: #00000098;
  z-index: 999;
  position: fixed;
  .form_wrp {
    max-width: 400px;
    background-color: black;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 70dvh;
    overflow: scroll;
    height: auto;
    border-radius: 5px;
    padding: 0px;
    backdrop-filter: blur(2px);
    background-image: linear-gradient(90deg, transparent 50%, #c01e2e 50%);
    background-size: 80px 80px;
    background-color: #ee1c25;
    .close {
      justify-content: flex-end;
      display: flex;
      width: 100%;
      align-items: flex-end;
      cursor: pointer;
      padding: 10px;
    }
    .win_rwp {
      align-items: center;
      text-align: center;
      justify-content: center;
      display: flex;
      overflow: scroll;
      flex-direction: column;

      p {
        color: white !important;
        font-size: 13px !important;
      }
      h1 {
        font-family: "Bebas Neue", sans-serif !important;
        color: #ffd500;
        margin-bottom: 10px;
      }
      h2 {
        color: white !important;
        letter-spacing: 2px;
        font-size: 40px;
        font-family: var(--wheel-font) !important;
      }
      small {
        color: rgb(255, 255, 255) !important;
        font-size: 17px !important;
        line-height: 1;
        em {
          font-style: normal;
          font-weight: 600;
        }
      }

      img {
        width: 100%;
        width:200px;
      }
    }
  }
  .code_wrp {
    padding: 10px 20px;
    background-color: rgb(0, 0, 0);
    em {
      font-style: normal;
      font-weight: 600;
    }
  }
  .endMsg {
    height: 100dvh;
    width: 100dvw;
    background-color: #ee1c25;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    .endMsgwrp {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: auto;
      max-width: 400px;
      padding: 20px;
    }
    .check_wrp {
      width: 100%;
      height: auto;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .check_wrp_in {
        width: 100px;
        display: flex;
        height: 100px;
        overflow: hidden;
        img {
          display: flex;
          border-radius: 100px;
          overflow: hidden;
        }
      }
    }
  }
}
.wheel_form {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  background-color: rgb(0, 0, 0);
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding: 20px;
}

// border-top: 1px dashed rgba(255, 255, 255, 0.902);
.wheel_form_hed {
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  strong {
    color: #ffffff;
    text-transform: uppercase;
  }
}
em {
  font-style: normal;
  text-transform: capitalize;
  color: white;
  font-weight: 600;
}

.__gradient {
  background: rgb(245, 242, 255);
  background: radial-gradient(
    circle,
    rgba(245, 242, 255, 1) 0%,
    rgba(185, 165, 255, 1) 27%,
    rgba(130, 102, 196, 1) 54%,
    rgba(4, 52, 147, 1) 100%
  );
}
.wheel_back {
  width: 100%;
  height: auto;
  // top: -60px;
  position: absolute;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  img {
    height: 100%;
  }
}
.wheel_con {
  width: 100%;
  // min-height: 80dvh;
}

.spin_wheel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.resetPassWrp {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  form {
    width: 100%;
    // max-width: 300px !important;

    input {
      border: 1px solid #aaaa;
      border-radius: 3px;
      width: 100% !important;
      padding: 10px 15px !important ;
      margin-bottom: 10px;
      outline: none;
    }
    select {
      border: 1px solid #aaaa;
      border-radius: 3px;
      width: 100% !important;
      padding: 10px 15px !important ;
      margin-bottom: 10px;
      outline: none;
    }
    button {
      //border: 1px solid #aaaa;
      outline: none !important;
      border-radius: 3px;
      width: 100%;
      padding: 10px 20px;
      margin-bottom: 20px;
      cursor: pointer;
      border: none;
      transition: 0.3s;
      color: white;
      background-color: #3b82f6;
    }
    button:hover {
      background-color: #5c9bff;
      transition: 0.3s ease-in;
      color: rgb(255, 255, 255);
      // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.233), 0 4px 12px rgba(0, 0, 0, 0.231);
    }
  }
}
.head {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  p {
    color: white;
    text-align: center;
    font-size: 14px !important;
    margin-bottom: 20px;
  }
}
.div_wrp {
  width: 100%;
  display: flex;
  gap: 10px;
}
