.__footer {
  width: 100vw;
  height: auto;
  margin: auto;
  background-color: #10101b;

  p {
    font-size: 12px !important;
    color: rgb(255, 255, 255) !important;
  }
  h2 {
    color: #fcb315 !important;
    font-size: 15px !important;
  }
  .__footWrap {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr ;
    grid-gap: 100px;
    .__footSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      text-align: center;
      img {
        width: 200px;
      }
      .__f_contact {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 50px;
        text-align: center;
      }
    }
    .__footSection_two {
      justify-content: space-evenly;
      align-items: start;
      display: flex;
      h2{
        color:white !important
      }

      ul {
        padding: 0 !important;
        line-height: 2;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .__footWrap {
      text-align: center;
      grid-template-columns: 1fr;
      padding: 40px !important;
        grid-gap: 50px !important;
    }
  }
  .__copy_right {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(32, 32, 32);
    .__container {
      .__copy_text {
        p {
          padding-top: 13px;
          font-size: 12px !important;
          color: #fcb315 !important;
        }
      }
    }
  }
  .f_btn{
    color: #fcb315 !important;
  }
  .f_btn:hover  {
    color: #f5f5f5 !important;
  }
}
@media screen and (max-width:992px) {
  ._f_des{
    width: auto !important;

  }
  
}
._f_des{
  padding-top: 10px;
  width: 400px;
}
hr{
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #f0f0f0;
  padding: 0;
}