// .xl__home {
//   margin: 0;
//   // width: 100vw;
//   // height: 100vh;
//   background-image: repeating-linear-gradient(
//     130deg,
//     transparent,
//     transparent 8px,
//     #c7c7c71d 1px,
//     #cccccc34 9px
//   );
//   display: flex;
//   align-items: center;
//   text-align: center;
//   justify-content: center;
//   place-items: center;
//   overflow: hidden;
//   .xl__home__wrap {
//     padding: 20px;
//     margin: 20px;
//     position: relative;
//     width: 500px;
//     height: 242px;
//     border-radius: 3px;
//     box-sizing: border-box;
//     backdrop-filter: blur(7px);
//     // background-color: rgb(255, 255, 255);
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
//       rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
//     button {
//       display: block;
//       width: 100%;
//       padding: 0;
//       border: none;
//       outline: none;
//       box-sizing: border-box;
//       color: white;
//       margin-top: 20px;
//       background: orange;
//       height: 40px;
//       border-radius: 3px;
//       cursor: pointer;
//       font-weight: 900;
//       // box-shadow: 6px 6px 6px rgba(47, 124, 123, 0.467),
//       //   -6px -6px 6px rgba(255, 255, 255, 0.467);
//       transition: 0.5s;
//     }

//     button:hover {
//       box-shadow: none;
//     }
//   }

//   .xl__home__wrap {
//     margin: 20px;
//     position: relative;
//     width: 100%;
//     max-width: 500px;
//     height: auto;
//     border-radius: 3px;
//     box-sizing: border-box;
//     backdrop-filter: blur(7px);
//     // background-color: rgb(255, 255, 255);
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
//       rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

//     .charge_bk {
//       background-color: rgb(255, 0, 0);
//     }

//     .xl__coupen__form {
//       padding: 20px;
//       .xl__brand__title {
//         margin-top: 20px;
//         font-weight: 400;
//         font-size: 1rem;
//         color: #ffffff !important;
//         span {
//           color: #ffffff !important;
//           font-weight: 500 !important;
//         }
//       }

//       .__form {
//         width: 100%;
//         padding: 10px;
//         inputs {
//           width: 100%;
//           display: grid;
//           grid-template-columns: 3fr 0.5fr 1fr;
//           grid-gap: 5px;

//           .__space {
//             align-items: center;
//             justify-content: center;
//             display: flex;
//             color: rgb(180, 180, 180);
//           }

//           input::placeholder {
//             color: rgb(180, 180, 180);
//           }
//           input {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             text-align: center;
//             width: 100%;
//             padding: 0;
//             border: none;
//             outline: none;
//             box-sizing: border-box;
//             background: #ecf0f3;
//             padding: 10px;
//             height: 50px;
//             font-size: 16px;
//             border-radius: 3px;
//             // box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
//             text-transform: unset;
//           }
//         }
//         button {
//           display: block;
//           width: 100%;
//           padding: 0;
//           border: none;
//           outline: none;
//           box-sizing: border-box;
//           color: white;
//           margin-top: 20px;
//           background: orange;
//           height: 40px;
//           border-radius: 3px;
//           cursor: pointer;
//           font-weight: 900;
//           // box-shadow: 6px 6px 6px rgba(47, 124, 123, 0.467),
//           //   -6px -6px 6px rgba(255, 255, 255, 0.467);
//           transition: 0.5s;
//         }

//         button:hover {
//           box-shadow: none;
//         }
//       }
//       img {
//         width: 400px !important;
//       }
//     }
//   }
// }
// .err_msg {
//   margin-top: 10px;
//   color: rgb(246, 0, 0);
//   font-size: 14px;
// }
.home_wrp {
  padding: 2% 5%;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .__wheel {
    // padding: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .back_img {
      width: 100%;
      height: auto;
      img {
        width: 100%;
      }
    }
    ._logo {
      width: 100%;
      margin-bottom: 20px;
      img {
        width: 60%;
      }
    }
    @media screen and (min-width: 900px) {
      ._logo {
        display: none;
      }
    }
  }
  .__info {
    // padding: 50px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .xl__brand__title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      span {
        color: white !important;
      }
    }

    ._logo {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      margin-top: 20px;
      img {
        width: 50%;
      }
    }

    ._gift {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 60%;
      }
    }
    .wrongCode {
      cursor: pointer;
      background-color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
      padding: 20px 40px;
      p {
        color: white !important;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
    }

    .__form {
      background-color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
      padding: 20px 40px;

      inputs {
        max-width: 400px;
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 0.5fr 1fr;
        grid-gap: 5px;

        .__space {
          align-items: center;
          justify-content: center;
          display: flex;
          color: rgb(180, 180, 180);
        }

        input::placeholder {
          color: rgb(180, 180, 180);
        }
        input {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 100%;
          padding: 0;
          border: none;
          outline: none;
          box-sizing: border-box;
          background: #ecf0f3;
          padding: 10px;
          height: 50px;
          font-size: 16px;
          border-radius: 3px;
          // box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
          text-transform: unset;
        }
      }
      button {
        display: block;
        width: 100%;
        padding: 0;
        border: none;
        outline: none;
        box-sizing: border-box;
        color: white;
        margin-top: 20px;
        background: orange;
        height: 40px;
        border-radius: 3px;
        cursor: pointer;
        font-weight: 900;
        // box-shadow: 6px 6px 6px rgba(47, 124, 123, 0.467),
        //   -6px -6px 6px rgba(255, 255, 255, 0.467);
        transition: 0.5s;
      }

      button:hover {
        box-shadow: none;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .home_wrp {
    width: 100%;
    grid-template-columns: 1fr;
    .__wheel {
      order: 2;
      ._logo {
        img {
          display: none;
        }
      }
    }
  }
}
