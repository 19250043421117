@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

:root {
  --wheel-font: "Bebas Neue", sans-serif;
  --wheel-size: 310px;
  --wheel-slice-spacing: 50px;
  --wheel-border-size: 10px;
  --wheel-color: rgb(255, 255, 255);
  --neutral-color: rgb(255, 255, 255);
  --PI: 3.38;
  --nb-item: 0;
  --item-nb: 0;
  --selected-item: 0;
  --nb-turn: 2;
  --spinning-duration-per-round: 5s;
  --spinning-duration: calc(var(--nb-turn) * 3s);
  /* --reset-duration: 0.01s; */
}

/* Wheel container = outer pink circle */
.wheel-container {
  display: block;
  position: relative;
  box-sizing: content-box;
  width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  padding: 0px;
  margin: auto;
  /* background-color: var(--neutral-color);
  border: solid rgb(0, 0, 0) 5px; */
  border-radius: 50%;
  user-select: none;
  z-index: 2;
}
.__logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.__logo img {
  width: 100px;
}
.border {
  position: relative;
  margin: 20px;
  border-radius: 50%;
  padding: 2px;
  background-color: rgba(64, 34, 9, 0.931);
}
.blackBorder {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  padding: 20px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.wheel-item:nth-child(1) {
  color: #9902d4;
}

.wheel-item:nth-child(2) {
  color: #35a038;
}

.wheel-item:nth-child(3) {
  color: black;
}

.wheel-item:nth-child(4) {
  color: #35a038;
}

.wheel-item:nth-child(5) {
  color: #ea6702;
}

.wheel-item:nth-child(6) {
  color: #35a038;
}

.wheel-item:nth-child(7) {
  color: #ba151e;
}

.wheel-item:nth-child(8) {
  color: #35a038;
}

.wheel-item:nth-child(9) {
  color: #f3d400;
}

/*------------------*/
.choco_wheel-item:nth-child(1) {
  color: #ff0000;
}

.choco_wheel-item:nth-child(2) {
  color: #f3d400;
 
}

.choco_wheel-item:nth-child(3) {
  color: #ff0000;
}

.choco_wheel-item:nth-child(4) {
  color: #720000;
}

.choco_wheel-item:nth-child(5) {
  color: #ff0000;
}

.choco_wheel-item:nth-child(6) {
  color: #f3d400;
}

.choco_wheel-item:nth-child(7) {
  color: #ff0000;
}

.choco_wheel-item:nth-child(8) {
   color: #720000;
}

.choco_wheel-item:nth-child(9) {
 color: #ff0000;
}
/* 
.wheel_wrp {
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  /* background-color: #f4d600; 
} */

.wheel_wrp {
  overflow: hidden;
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  /* background-color: #f4d600; */
}

.wrp_btn {
  margin-top: 40px;
  position: relative;
  display: flex;

  align-items: center;
  justify-content: center;
}
.wrp_btn img {
  width: 300px;
}
.sp_btn {
  cursor: pointer;
  top: 50%;
  z-index: 5 !important;
  transform: translateY(-50%);
  position: absolute;
  background-color: rgb(0, 0, 0);
  width: 80%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 80px;
  border-radius: 50px;
}
.sp_btn h2 {
  color: rgb(255, 255, 255);
  font-family: "Bebas Neue", sans-serif !important;
}

.border_img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.border_img img {
  width: 420px;
}

/* Selection triangle = 1 pink + 1 white */
.wheel-container::before,
.wheel-container::after {
  content: "";
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  border: solid transparent 25px;
  border-left-width: 0;
}

.wheel-container::before {
  right: 0px;
  border-right-color: black;
}

.wheel-container::after {
  right: -2px;
  border-right-color: rgb(0, 0, 0);
  /* border-right-color: var(--neutral-color); */
}

/* Wheel */
.wheel {
  display: block;
  position: relative;
  box-sizing: content-box;
  margin: auto;
  width: var(--wheel-size);
  height: var(--wheel-size);
  overflow: hidden;
  border-radius: 50%;
  border: solid var(--wheel-color) var(--wheel-border-size);
  background-color: var(--wheel-color);
  /* transition: transform var(--reset-duration); */
  transform: rotate(0deg);
  cursor: pointer;
  z-index: 2;
}

.wheel.spinning {
  transition: transform var(--spinning-duration) linear;
  /* transform: rotate(calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1)))); */
  transform: rotate(
    calc(
      var(--nb-turn) * 360deg +
        (360deg * (var(--nb-item) - var(--selected-item)) / var(--nb-item, 1))
    )
  );
}

/* Center of the wheel = white circle in the center */
.wheel::after {
  display: block;
  position: absolute;
  content: "";
  /* background-color: var(--neutral-color); */
  width: 50px;
  height: 50px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: solid black var(--wheel-border-size);
}

/* Element on the wheel */
.wheel-item {
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 100%;

  /* position de l'item */
  top: 50%;
  left: 50%;
  width: 50%;
  transform-origin: center left;
  transform: translateY(-50%)
    rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

  /* text */
  color: rgb(255, 255, 255);
  font-size: 70px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: right;
  padding: 0 25px 0 50px;
  font-family: var(--wheel-font);
}

/* Element background = lighter pink triangle */
.wheel-item:before {
  content: " ";
  display: block;
  position: absolute;
  box-sizing: border-box;
  z-index: -1;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 0px;
  opacity: 0.25;

  /* 
Triangle width
     I don't know why I need to add
       " + var(--wheel-size) / 2 " in slice-max-width ==> gives 2.PI.R + R
     but overall it works pretty well
     TODO: To study 
  */
  --slice-max-width: calc(
    var(--PI) * var(--wheel-size) + var(--wheel-size) / 4
  );
  --slice-width: calc(
    (var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing)
  );
  border: solid rgba(255, 255, 255, 0.3) calc(var(--slice-width) / 1.4);
  border-left: solid rgba(255, 255, 255, 0);
  /* triangle depth = wheel center to border = 300px / 2 */
  border-right: solid calc(var(--wheel-size) / 2);
}



/* Element on the wheel */
.choco_wheel-item {
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 100%;

  /* position de l'item */
  top: 50%;
  left: 50%;
  width: 50%;
  transform-origin: center left;
  transform: translateY(-50%)
    rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

  /* text */
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height:1;
  text-align: center;
  text-align: right;
  padding: 0 25px 0 50px;
  font-family: var(--wheel-font);
}


.choco_wheel-item::before {
  content: " ";
  display: block;
  position: absolute;
  box-sizing: border-box;
  z-index: -1;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 0px;
  opacity: 0.25;

  /* 
Triangle width
     I don't know why I need to add
       " + var(--wheel-size) / 2 " in slice-max-width ==> gives 2.PI.R + R
     but overall it works pretty well
     TODO: To study 
  */
  --slice-max-width: calc(
    var(--PI) * var(--wheel-size) + var(--wheel-size) / 4
  );
  --slice-width: calc(
    (var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing)
  );
  border: solid rgba(255, 255, 255, 0) calc(var(--slice-width) / 1.4);
  border-left: solid rgb(255, 255, 255) ;
  /* triangle depth = wheel center to border = 300px / 2 */
  border-right: solid calc(var(--wheel-size) / 2);
}
