.error {
  padding: 100px;
  width: 100%;
  height: 80vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
background: rgb(255,255,255);

  .error_wrap {
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: auto;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    padding: 60px 100px;
    .error_img {
      width: 100%;
    }
    .error_text {
      width: 100%;
      span {
        font-size: 80px !important;
        line-height: 1;
        color: rgb(85, 85, 85);
        font-weight: 300;
      }

      p {
        padding: 30px 0px;
      }
      button {
        background-color: #1c8aeb;
        border: none;
        padding: 10px 30px;
         border-radius: 3px;
        color: white;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .error {
    padding: 20px !important;
    .error_wrap {
      padding: 0px !important;
      .error_bn {
        img {
          width: 100%;
        }
      }
       .error_text {
        width: 100%;
        span{
          font-size: 50px  !important;
        }
       }
    }
  }
}
.error_bn {
  width: 100%;
  height: auto;
  img {
    width: 400px;
  }
}
